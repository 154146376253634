@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  -webkit-tap-highlight-color: transparent;
}

.rotate-y-180{
  transform: scale(-1, -1);
  /* transform: rotateY(180deg); */
  /* transform: rotateX(180deg); */
}

.bg-hero-gradient{
 background: rgb(94,125,209);
background: radial-gradient(circle, rgba(94,125,209,1) 0%, rgba(52,78,148,1) 61%, rgba(1,22,79,1) 100%);
}

.text-shadow{
  text-shadow: 2px solid white;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
